<template>
  <v-card c flat tile outline color="grey lighten-1 px-3">
    <h2 class="mb-3 secondary--text">
      {{ $t(title) }}
    </h2>

    <v-card-text class="pa-0">
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-1 mb-2"
      >
        <strong>{{ $t("checkout.summary.itemsTotal") }}</strong>
        <strong>
          {{ $n(netTotal, "currency") }}
        </strong>
      </v-row>
      <!-- totalPriceVariable -->
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-2 mb-5"
      >
        <span>({{ $t("checkout.summary.itemsTotalByWeight") }})</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </v-row>

      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-2 mb-2"
        v-if="orderCart.deliveryFee > 0"
      >
        <span>{{ deliveryFeeTitle }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </v-row>
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-2 mb-2"
        v-if="orderCart.packageTotal > 0"
      >
        <span>{{ $t("checkout.summary.depositFee") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </v-row>

      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-2"
        v-if="orderCart.taxTotal"
      >
        <span>{{ $t("checkout.summary.taxTotal") }}</span>
        <span>{{ $n(orderCart.taxTotal, "currency") }}</span>
      </v-row>

      <!-- saved -->
      <v-row
        v-if="saved > 0"
        no-gutters
        justify="space-between"
        align="center"
        class="mt-4 text-body-1 font-italic primary--text font-weight-bold"
      >
        <span>{{ $t("checkout.summary.saving") }}</span>
        <span>{{ $n(saved, "currency") }}</span>
      </v-row>
      <v-row no-gutters class="d-flex justify-space-between align-center mt-6">
        <h4>{{ $t("checkout.summary.total") }}</h4>
        <h3>
          <OrderGrossTotal
            :item="orderCart"
            :delivered="delivered"
          ></OrderGrossTotal>
        </h3>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";

export default {
  name: "CheckoutSummary",
  components: { OrderGrossTotal },
  props: {
    orderCart: { type: Object, required: true },
    delivered: { type: Boolean, default: false },
    title: { type: String, default: "checkout.summary.cart" }
  },
  computed: {
    saved() {
      return (
        this.orderCart.orderAdjustmentSummary?.TYPE_ITEM_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary?.TYPE_NET_TOTAL.totalVariation
      );
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    netTotal() {
      return this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
    },
    deliveryFeeTitle() {
      return this.$t(
        "checkout.summary.deliveryFeeTitle." +
          this.orderCart.shippingAddress.addressType
      );
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    }
  }
};
</script>
